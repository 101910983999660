import { VoucherActionTypes } from "../redux/ActionTypes"

const INITIAL_STATE: VoucherState = {
  voucherDetail: {},
  error: {},
  WinnerCodeErrorMessage: "",
  WinnerCodeSuccessMessage: "",
  loading: false,
}

interface Action {
  payload: any
  type: string
}

export const VoucherReducer = (
  state: VoucherState = INITIAL_STATE,
  action: Action,
): VoucherState => {
  switch (action.type) {
    case VoucherActionTypes.VOUCHER_CODE_START:
      return {
        ...state,
        loading: true,
      }

    case VoucherActionTypes.VOUCHER_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        voucherDetail: action.payload,
      }

    case VoucherActionTypes.VOUCHER_CODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

      case VoucherActionTypes.SEND_WINNER_CODE_START:
        return {
          ...state,
          loading: true,
        }

      case VoucherActionTypes.SEND_WINNER_CODE_SUCCESS:
        return {
          ...state,
          loading: false,
          WinnerCodeSuccessMessage: action.payload,

        }

      case VoucherActionTypes.SEND_WINNER_CODE_FAIL:
        return {
          ...state,
          loading: false,
          WinnerCodeErrorMessage: action.payload,

        }
    default:
      return state
  }
}
