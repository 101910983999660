import React, { Component } from "react"
import ReactGA from "react-ga"
import Modal from "react-modal"
import { Provider } from "react-redux"
import { Route, Switch } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { applyMiddleware, compose, createStore } from "redux"
import ReduxThunk from "redux-thunk"

import reducers from "./@redux/redux/reducer"
import "./App.css"

Modal.setAppElement("#modal-root")
class App extends Component {
  public render() {
    ReactGA.initialize("UA-156345665-1")
    const composeEnhancers =
      (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose

    const store = createStore(
      reducers,
      composeEnhancers(applyMiddleware(ReduxThunk)),
    )
    return (
      <Provider store={store}>
        <ToastContainer />
        <Switch>
          <Route
            path="/"
            component={() => {
              window.location.href = "https://www.blackbottle.com"
              return null
            }}
          />
        </Switch>
      </Provider>
    )
  }
}

export default App
