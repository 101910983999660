
export const VoucherActionTypes = {
  VOUCHER_CODE_START: "VOUCHER_CODE_START",
  VOUCHER_CODE_SUCCESS: "VOUCHER_CODE_SUCCESS",
  VOUCHER_CODE_FAIL: "VOUCHER_CODE_FAIL",

  SEND_WINNER_CODE_START: "SEND_WINNER_CODE_START",
  SEND_WINNER_CODE_SUCCESS: "SEND_WINNER_CODE_SUCCESS",
  SEND_WINNER_CODE_FAIL: "SEND_WINNER_CODE_FAIL",
}
